/* eslint-disable max-len */
export default {
	contactEmail: 'dan3002.work@gmail.com',
	startDate: 2019,
	home: {
		roles: ['a Software Engineer', 'a DevOps Engineer', 'an AWS Authorized Instructor'],
	},
	about: {
		paragraphs: [
			'I had the opportunity to start college at the age of 16, and started working a year later (17 years old). My interests are in cloud computing as well as software development.',
			'Along with that, I also have experience in teaching, I was recognized as an AWS Authorized Instructor and was the main instructor in 2 Bootcamps in Vietnam.',
			'I am always open to new opportunities and challenges. If you would like to reach me, feel free to drop me a message in the email below.',
		],
	},
	others: [
		'<b>Languages</b>: English | Vietnamese: native.',
		'<b>Programming languages</b>: Go, Python, JavaScript, Bash, Java, SQL.',
		'<b>Frameworks & Libraries</b>: MERN Stack, VueJS, NextJS, Flask, Django, ...',
		'<b>Other</b>: Langchain, Linux, Git, Docker, AWS, k8s, ...',
	],
	education: [{
		title: 'FPT University',
		timeline: 'Jan 2022 - Aug 2023',
		subtitle: 'Major: Software Engineering.',
		text: [
			'Graduated at the <b>age of 20</b>, two years ahead of schedule.',
			'Awarded a full scholarship covering the entirety of the study period.',
			// 'I am able to complete my undergraduate degree in a year and a half due to an exemption of 50% of required subjects in the entire study program.',
			// 'GPA: 8.0/10',
		],
	}, {
		title: 'FUNiX University',
		timeline: 'Aug 2018 - Dec 2021',
		subtitle: 'Major: Software Engineering.',
		text: [
			'Started studying at the age of 16.',
			'Received a scholarship each semester and actively participated in various university activities.',
			// 'Had the opportunity to have personal meetings with the university\'s principal (<a href="https://vi.wikipedia.org/wiki/Nguy%E1%BB%85n_Th%C3%A0nh_Nam_(doanh_nh%C3%A2n)">Mr. Nguyen Thanh Nam</a> - Founder of FUNiX, former General Director of FPT).',
			// 'GPA: 8.4/10',
		],
	}],
	experience: [{
		title: 'VPBank',
		timeline: 'Aug 2024 - Present',
		subtitle: 'DevOps Engineer',
		text: [
			'Aligning ESB architecture with microservices and cloud environments, focusing on stability, performance, and scalability.',
			'Utilizing GitOps framework to automate monitoring, enabling proactive management and real-time issue resolution.',
			'Re-architecting existing APIs and services to integrate with modern microservices and message-oriented middleware (IBM MQ).',
			'Designing and implementing CI/CD pipelines to streamline deployment and testing, ensuring smooth integration and delivery of new features.',
		],
	}, {
		title: 'OpenCommerce Group',
		timeline: 'Mar 2024 - July 2024',
		subtitle: 'Full-Stack Developer',
		text: [
			'Participate in the development of the Dropshipping e-commerce system.',
			'Enhance and develop payment and related systems using Golang and VueJS. Integrate with payment services such as Stripe, PayPal, and others.',
		],
	}, {
		title: 'FUNiX',
		timeline: 'Aug 2021 - Feb 2024',
		subtitle: 'Program Development Officer & Full-Stack Developer',
		text: [
			'Contributed to the development of internal applications, including the Mentoring System and Learning Management System (LMS).',
			'Managed cloud-based resources for the entire team, ensuring efficient and secure access to the necessary tools and resources.',
			'Assumed responsibility for designing and developing xSeries specialized courses, including <b>Full Stack with MERN Stack, Data Engineering, Cloud Computing, and DevOps, ...</b>',
			// 'In addition, I collaborated with other instructors to create comprehensive curriculum for each course and provided hands-on training to students through practical projects and exercises. The courses I developed have received positive feedback from students and helped them achieve better results in their field.',
		],
	}, {
		title: 'FPT Software',
		timeline: 'Aug 2020 - Aug 2021',
		subtitle: 'Backend Developer',
		text: [
			'Balanced my role as a part-time employee while still attending high school, gaining valuable work experience while completing my education.',
			'Contributed to the development of internal applications, including the Mentoring System and Learning Management System (LMS).',
			// 'Assisted in the implementation and operation of projects by monitoring and resolving bugs.',
		],
	}, {
		title: 'FUNiX\'s IT department',
		timeline: 'Aug 2019 - Aug 2020',
		subtitle: 'Backend Developer',
		text: [
			'Started working immediately after my first year of university, at the age of 17, gaining valuable experience and skills while pursuing my education.',
			'Developed a learning tool and management software using Javascript, NodeJS, and Firebase, providing an interactive and user-friendly platform for students and instructors.',
			// 'Developed and deployed a Chrome Extension that translates subtitles on MOOC websites (such as Udemy and Coursera) and the content of the page into Vietnamese, providing a more accessible and user-friendly learning experience for students.',
		],
	}],
	activities: [{
		title: 'AWS Academy',
		timeline: 'Sep 2022 - Present',
		subtitle: 'AWS Authorized Instructor',
		text: [
			'Selected as a lecturer for the AWS Academy Cloud Training Bootcamp in Vietnam, organized by FUNiX and <a href="https://aws.amazon.com/training/awsacademy/">AWS Academy</a>, where I shared my knowledge and experience in the field of cloud computing with students.',
			'Assumed responsibility for instructing an <b>AWS Cloud Foundatio</b> and <b>AWS Solutions Architect - Associate</b> course, with a class size of nearly 800 students.',
		],
	}, {
		title: 'FUNiX',
		timeline: 'Oct 2021 - Present',
		subtitle: 'Mentor for the FUNiX\'s program',
		text: [
			'Assisted in providing answers to students\' inquiries regarding FUNiX subjects, with a focus on information technology and soft skills, ensuring that their academic and technical queries are resolved.',
			'Participated in the evaluation process by administering exams to determine student competence and readiness, ensuring they met course requirements.',
		],
	}, {
		title: 'JS Club - Japanese Software Engineers',
		timeline: 'May 2022 - Present',
		subtitle: 'Member - Academic Department',
		text: [
			// 'Instructed a class of 15 students in Java Object-Oriented Programming for a period of 10 weeks, resulting in all students passing the course at university level with a grade of 80% or higher.',
			// 'Actively participated in deploying contest environments for Competitive Programming competitions.',
			'My university club where we share knowledge and skills at programming and software development. We organize training classes that help with improving coding skills, academic events, tech sharing sessions and more.',
		],
	}, {
		title: 'Developh Vietnam',
		timeline: 'July 2021 - Dec 2021',
		subtitle: 'Techcontent Mentor',
		text: [
			'Mentor of the <a href="https://www.facebook.com/develophvn/photos/pcb.334074128129033/334072064795906/">DIY Camp</a> program, where I provided guidance and support to students in developing their own projects.',
			// 'Develop and maintain the official website for <a href="https://www.facebook.com/develophvn">Developh Vietnam</a> - a social enterprise that aims to make technology education accessible to children in Vietnam by conducting coding classes and has reached over 6,000 followers on Facebook.',
		],
	}, {
		title: 'Codelearn',
		timeline: 'Aug 2020 - Aug 2021',
		subtitle: 'Problem Setter and Blog Writer',
		text: [
			'Developed and reviewed competitive programming challenges for weekly and monthly contests, ensuring high-quality and engaging problems for participants.',
			'Achieved a top ranking on the leaderboard.',
		],
	}],
	projects: [{
		title: 'Architecture Design Generator',
		timeline: 'Jun 2024',
		subtitle: 'Fullstack Developer',
		text: [
			'An application designed to provide various functionalities to help users create and manage architecture design diagrams more effectively. With the power of ChatGPT 4, our application can provide users the ability to create architecture design from scratch with a more interactive and user-friendly experience.',
			'Champion of VPBank Hackathon 2024',
			'<b>Technologies: </b>ReactJS, Langchain, Go, AWS, ...',
			'<a href="https://github.com/bhdl-ai/vpbank-hackathon-fe">Source code</a>',
		],
	}, {
		title: 'FinAInce Assistant',
		timeline: 'Apr 2023 - May 2023',
		subtitle: 'Fullstack Developer & Cloud Engineer',
		text: [
			'<b>FinAInce Assistant</b> is a financial chatbot designed to provide various functionalities to help users manage their finances more effectively. With the power of ChatGPT, our chatbot predicts user behavior, automates basic tasks, and offers real-time financial insights.',
			'Champion of FUNiX ChatGPT Hackathon',
			'<b>Technologies: </b>ReactJS, Flask, GPC Cloud, Firebase, ChatGPT Prompt, ...',
			'<a href="https://github.com/DAN3002/FinAInce-Assistant-Webapp">Source code</a>',
		],
	}, {
		title: 'Doctor’s Cyclop',
		timeline: 'Apr 2021',
		subtitle: 'Fullstack Developer & Cloud Engineer',
		text: [
			'Created a website that implements the machine learning model for classifying the presence and correct placement of tubes on chest x-rays, allowing for easy access and practical application of the model by medical professionals.',
			'Top 3 FPT Edu Hackathon 2021 - beat over 100 teams from different Universities.',
			'<b>Technologies: </b>MeteorJS, MongoDB, AWS Cloud',
			'<a href="https://github.com/DAN3002/Doctors-Cyclop-Webapp">Source code</a>',
		],
	}],
	certifications: [{
		name: 'AWS Certified Solutions Architect - Associate',
		category: 'AWS Certificate',
		thumb: '/images/achieves/cert/AWS Certified Solutions Architect - Associate_banner.png',
		image: '/images/achieves/cert/AWS Certified Solutions Architect - Associate.png',
		url: 'https://www.credly.com/badges/8c2432fa-b400-4ecf-ab18-783351e6128b',
		start: 'November 19, 2021',
		end: 'November 19, 2024',
	}, {
		name: 'AWS Certified Cloud Practitioner',
		category: 'AWS Certificate',
		thumb: '/images/achieves/cert/AWS Certified Cloud Practitioner_banner.png',
		image: '/images/achieves/cert/AWS Certified Cloud Practitioner.png',
		url: 'https://www.credly.com/badges/1f3d4dc4-c239-4003-a9c6-f0ee578bc31d',
		start: 'October 05, 2021',
		end: 'November 19, 2024',

	}, {
		name: 'AWS Academy Certified Educator',
		category: 'AWS Certificate',
		thumb: '/images/achieves/cert/AWS Academy Certified Educator.png',
		image: '/images/achieves/cert/AWS Academy Certified Educator.png',
		url: 'https://www.credly.com/badges/7b48e869-5319-4d9f-ae19-9ca358c1842e',
		start: 'October 05, 2021',
		end: 'April 14, 2024',

	}],
	achievements: [{
		name: 'VPBank Hackathon 2024',
		category: 'Hackathon',
		thumb: '/images/achieves/vpbank-hackathon-2024.png',
		image: '/images/achieves/vpbank-hackathon-2024-banner.jpg',
		date: 'Jun 2024',
		prize: 'Champion',
		text: [
			'An application designed to provide various functionalities to help users create and manage architecture design diagrams more effectively. With the power of ChatGPT 4, our application can provide users the ability to create architecture design from scratch with a more interactive and user-friendly experience.',
			'Beat over 150 teams from different Companies and Universities.',
		],
		link: [],
	}, {
		name: 'FPT Edu ResFes 2023',
		category: 'Research Festival',
		thumb: '/images/achieves/resfes-2023.jpg',
		image: '/images/achieves/resfes-2023-banner.jpg',
		date: '20 August, 2023',
		prize: 'Third Prize',
		text: [
			'- Discovered and developed an ecosystem to apply an AI model call Relahash. Achieving unparalleled accuracy and minimal latency in image retrieval for the fashion e-commerce landscape.',
			'- Designed and implemented a declarative crawling system that allows users to specify their requirements and let the system do the heavy lifting.',
			'- Built a rich dataset of 7.5M+ images from 8K+ shops across 30 websites.',
			'- Built a robust ecosystem on Kubernetes to seamlessly combine our dynamic crawler and AI model pipeline.',
		],
		link: [],
	}, {
		name: 'FUNiX ChatGPT Hackathon',
		category: 'Hackathon',
		thumb: '/images/achieves/funix-chatgpt-hackathon.png',
		image: '/images/achieves/funix-chatgpt-hackathon-banner.jpg',
		date: '07 May, 2023',
		prize: 'Champion',
		text: [
			'Our team has won the first victory with the product "FinAInce Assistant - ChatGPT can take actions". This is a new Fintech product, built in nearly a month and using ChatGPT along with some special techniques to optimize operations for users related to finance. The success of the team is evidence of the great potential of ChatGPT in performing many tasks other than Q&A. With ChatGPT, the team has saved time and focused on product development effectively.',
		],
		link: [{
			text: 'Dự án trợ lý ảo ngân hàng giành quán quân ChatGPT Hackathon',
			url: 'https://vnexpress.net/du-an-tro-ly-ao-ngan-hang-gianh-quan-quan-chatgpt-hackathon-4603011.html',
		},
		{
			text: 'Sinh viên FUNiX chia sẻ về dự án vô địch ChatGPT Hackathon',
			url: 'https://vnexpress.net/sinh-vien-funix-chia-se-ve-du-an-vo-dich-chatgpt-hackathon-4604055.html',
		},
		],
	}, {
		name: 'FPT Edu Hackathon 2021',
		category: 'Hackathon',
		thumb: '/images/achieves/hackathon-2021.png',
		image: '/images/achieves/hackathon-2021-banner.jpg',
		date: '18 April, 2021',
		prize: 'Third Prize',
		text: [
			'As the youngest team to participate. Won third prize in a competition with 104 teams from various universities, for building a web application that utilizes AI to assist doctors in diagnosing Covid-19.',
		],
		link: [{
			text: '4 học sinh lớp 12 làm sản phẩm AI hỗ trợ điều trị Covid-19',
			url: 'https://vnexpress.net/4-hoc-sinh-lop-12-lam-san-pham-ai-ho-tro-dieu-tri-covid-19-4269038.html?fbclid=IwAR16uIHcQHmSkyvecoIT_z8IwSujveLDKXw7wImasg-5WxNR1Iym9cMJMh0',
		}],
	}, {
		name: 'FPT Edu Hackathon 2019',
		category: 'Hackathon',
		thumb: '/images/achieves/hackathon-2019.jpg',
		image: '/images/achieves/hackathon-2019-banner.jpg',
		date: '12 January, 2020',
		prize: 'Third Prize',
		text: [
			'As the youngest team to participate. Won third prize out of 35 teams coming from different Universities. Our team achieved third prize in a competition for developing equipment to check the safety of water.',
		],
		link: [{
			text: 'Học sinh THPT giành giải \'FPT Edu Hackathon\' 2019',
			url: 'https://vnexpress.net/hoc-sinh-thpt-gianh-giai-fpt-edu-hackathon-2019-4042313.html',
		}],
	}],
};
