/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */

function GoToTop() {
	return (
		<a href="javascript:" id="return-to-top">
			<i className="fas fa-arrow-up" />
		</a>
	);
}

export default GoToTop;
